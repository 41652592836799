import { defineStore } from "pinia";
import CoinService from "@/services/CoinService.js";

export const useCoinStore = defineStore("coinStore", () => {
  const coins = ref([
    {
      fiat: true,
      coinId: "fiatUsd",
      name: "US Dollar",
      symbol: "USD",
    },
    {
      fiat: true,
      coinId: "fiatEur",
      name: "Euro",
      symbol: "EUR",
    },
    {
      fiat: true,
      coinId: "fiatNok",
      name: "Norwegian Krone",
      symbol: "NOK",
    },
  ]);

  function fillCoins(updatedCoins) {
    //already existing 3 coins should not be added again, but others should be replaced
    for (const coin of updatedCoins) {
      const index = coins.value.findIndex((c) => c.coinId === coin.coinId);
      if (index === -1) {
        coins.value.push(coin);
      } else {
        coins.value[index] = coin;
      }
    }
  }

  function searchCoins(keyword) {
    return CoinService.searchCoins(keyword).then((coins) => {
      for (const coin of coins) {
        this.coins.push(coin);
      }
    });
  }

  return { coins, fillCoins, searchCoins };
});
