export default {
  async saveBinanceKeys(userid, keys) {
    const { apiClient } = await useAxios();
    return apiClient.post(`/api/user/${userid}/binance`, keys).then((data) => {
      return data;
    });
  },
  async getBinanceKeys(userid) {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.get(`/api/user/${userid}/binance`);
    return data.data;
  },
  async saveFiriKeys(userid, keys) {
    const { apiClient } = await useAxios();
    return apiClient.post(`/api/user/${userid}/firi`, keys).then((data) => {
      return data;
    });
  },
  async getFiriKeys(userid) {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.get(`/api/user/${userid}/firi`);
    return data.data;
  },
  async saveCoinbase(userid, code) {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.post(`/api/user/${userid}/coinbase`, {
      token: code,
    });
    return data.data;
  },
};
