import { defineStore } from "pinia";
import TradeService from "@/services/TradeService.js";
import SaleService from "@/services/SaleService";
import { useIntegrationStore } from "./integration";
import type { Trade, Sale, Coin, FiatCurrency } from "@/types/trade";
import { formatDate } from "@vueuse/core";

export const useTradeStore = defineStore("tradeStore", () => {
  const { calculatePnl } = useFilters();
  const integrationStore = useIntegrationStore();
  const fiatStore = useFiatStore();
  const userStore = useUserStore();
  const coinStore = useCoinStore();
  const stableCoins = ["USDC", "BUSD", "DAI", "TUSD", "PAX", "USDS", "GUSD"];

  //state
  let activeTrades = reactive<Trade[]>([]);
  const isFirstRun = ref(true);
  const cachedPrices = useLocalStorage<Record<string, number>>("prices", {});

  //watch the trades, and connect to binance to get the latest if not exist
  watch(
    activeTrades,
    (newVal, oldVal) => {
      if (!isFirstRun && newVal?.length === oldVal?.length) return;
      isFirstRun.value = false;

      newVal.forEach(({ coinName, quote }) => {
        const [symbol] = coinName.split("-");
        if (!symbol) return;

        const pair = symbol.trim() + quote;
        integrationStore.addConnection(pair);
      });
    },
    { immediate: true },
  );

  //getters
  const profitableTrades = computed(() =>
    activeTrades.filter((t) => t.pnl > 0),
  );

  const soldCoins = (trade: Trade) => {
    if (!trade.sales) return 0;
    const sold = trade.sales.reduce(
      (previous, current) => previous + Number(current.amount),
      0,
    );
    return sold;
  };

  const remainingCoins = (trade: Trade) => {
    const currentTrade = activeTrades.find((t) => t.id == trade.id);
    if (!currentTrade) return 0;
    return Number(
      currentTrade.originalAmount - soldCoins(currentTrade),
    ).toFixed(8);
  };

  /**
   * Checks if a trade is closed.
   * @param trade - The trade object to check.
   * @returns `true` if the trade is closed, `false` otherwise.
   */
  const isClosed = (trade: Trade) => {
    if (!trade) return true;
    return Number(remainingCoins(trade)) < 0.0001;
  };

  //actions
  function fillActive(data) {
    activeTrades.splice(0, activeTrades.length, ...data);
  }

  function addNewTrade(newTrade: Trade) {
    const userStore = useUserStore();
    return TradeService.createTrade(newTrade).then((newData) => {
      activeTrades.push(newData);
      userStore.setTradePosition();
    });
  }

  function updatePrices(cmp: number, coinPair: string, direction: string) {
    const updatedTrades = activeTrades.map((trade) => {
      if (trade.coinName.split("-")[0].trim() + trade.quote == coinPair) {
        const pnlObj = calculatePnl(trade, cmp);
        return {
          ...trade,
          pnl: pnlObj.pnl,
          pnlPercentage: pnlObj.percentage,
          cmpClass: direction == "up" ? "text-green-800" : "text-red-800",
          currentPrice: cmp,
          loading: false,
        };
      }
      return trade;
    });

    fillActive(updatedTrades);
  }

  function cachePrices() {
    cachedPrices.value = activeTrades.reduce(function (
      acc: Record<string, number>,
      trade,
    ) {
      acc[trade.id] = trade.currentPrice;
      return acc;
    }, {});
  }

  function updateTrade(trade: Trade) {
    return TradeService.updateTrade(trade).then((updatedTrade) => {
      const index = activeTrades.findIndex((t) => t.id == trade.id);
      if (index < 0) {
        activeTrades.push(updatedTrade);
      } else {
        activeTrades[index] = updatedTrade;
      }
    });
  }

  function deleteTrade(tradeIds: string[]) {
    return TradeService.deleteTrades(tradeIds).then(({ data }) => {
      console.log(data);
      if (data.length == 0) return;
      tradeIds.forEach((id) => {
        const index = activeTrades.findIndex((t) => t.id == id);
        if (index >= 0) {
          activeTrades.splice(index, 1);
        }
      });
    });
  }

  function closePosition(sale) {
    const currentTrade = activeTrades.find((x) => x.id == sale.trade);
    if (currentTrade) {
      currentTrade.sales.push(sale);
      currentTrade.amount -= sale.amount;
      if (currentTrade.amount <= 0.001) {
        //remove the trade
        const index = activeTrades.findIndex((t) => t.id == currentTrade.id);
        activeTrades.splice(index, 1);
      }
    }
  }

  async function deleteSale(saleId: string, trade: Trade) {
    const userStore = useUserStore();
    const sale = await SaleService.deleteSale(saleId);
    let currentTrade = activeTrades.find((t) => t.id == trade.id);
    // if currentTrade is undefined, then assign the trade to currentTrade
    if (!currentTrade) currentTrade = trade;

    const index = currentTrade!.sales.findIndex((s) => s._id === sale._id);
    currentTrade!.sales.splice(index, 1);
    currentTrade!.amount += sale.amount;

    userStore.setPnl(-1 * sale.pnl);
    return sale;
  }

  const convertToUserCurrency = (amount: number, coin: string) => {
    coin = getCoin(coin);

    const fiatObject = getFiatObject(coin);
    if (fiatObject) return amount * (fiatObject.rates[userCurrency.value] || 1);

    if (userCurrency.value === "EUR") {
      const priceOfCoin = getPriceOfCoinInEUR(coin);
      if (priceOfCoin) return amount * priceOfCoin;
    }

    const usdtPrice = getUSDTPrice(coin);
    if (usdtPrice) {
      const fiatObject = getFiatObject("USD");
      if (fiatObject)
        return amount * usdtPrice * (fiatObject.rates[userCurrency.value] || 1);
    }

    return amount;
  };

  const userCurrency = computed(() => {
    if (!userStore || !userStore.user || !userStore.user.currency) return "USD";
    return (
      (userStore.user.currency === "USDT" ? "USD" : userStore.user.currency) ||
      "USD"
    );
  });

  const getCoin = (coin: string) => {
    return stableCoins.includes(coin) ? "USD" : coin;
  };

  const getFiatObject = (coin: string) => {
    const todaysObject = fiatStore.fiats.find(
      (f) => formatDate(new Date(), "YYYY-MM-DD") === f.date && f.base === coin,
    );

    //if not found, return the latest ordered by date
    if (!todaysObject) {
      const orderedByDate = fiatStore.fiats.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
      return orderedByDate.find((f) => f.base === coin);
    }

    return todaysObject;
  };

  const getPriceOfCoinInEUR = (coin: string) => {
    const coinPair = coin + "EUR";
    const connectionExist = integrationStore.connections.find((c) =>
      c.url.includes(coinPair.toLowerCase()),
    );
    return connectionExist ? integrationStore.prices[coinPair]?.price : null;
  };

  const getUSDTPrice = (coin: string) => {
    const usdtCoinPair = coin + "USDT";
    const usdConnection = integrationStore.connections.find((c) =>
      c.url.includes(usdtCoinPair.toLowerCase()),
    );
    if (!usdConnection) {
      integrationStore.addConnection(usdtCoinPair);
      return null;
    }
    return integrationStore.prices[usdtCoinPair];
  };

  return {
    activeTrades,
    profitableTrades,
    soldCoins,
    remainingCoins,
    isClosed,
    fillActive,
    addNewTrade,
    updatePrices,
    updateTrade,
    deleteTrade,
    closePosition,
    deleteSale,
    cachePrices,
    cachedPrices,
  };
});
