export const useFiatStore = defineStore("FiatStore", () => {
  const fiats = ref(null);

  const setFiats = (data) => {
    fiats.value = data;
  };

  return {
    fiats,
    setFiats,
  };
});
