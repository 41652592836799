import { defineStore } from "pinia";
import IntegrationService from "@/services/IntegrationService.js";
import _ from "lodash";

export const useIntegrationStore = defineStore("integrationStore", () => {
  const prices = shallowRef({});
  const connections = ref([]);
  const paused = ref(true);

  const winner = computed(() => {
    let bestCoin = "";
    let rank = 0;
    for (const key in prices.value) {
      if (rank == 0) {
        rank = prices.value[key].rank;
        bestCoin = key;
        continue;
      }

      if (rank < prices.value[key].rank) {
        rank = prices.value[key].rank;
        bestCoin = key;
      }
    }
    return bestCoin;
  });

  const userStore = useUserStore();

  const saveBinanceKeys = (keys) => {
    return IntegrationService.saveBinanceKeys(userStore.user.id, keys);
  };

  const getBinanceKeys = () => {
    return IntegrationService.getBinanceKeys(userStore.user.id);
  };

  const saveFiriKeys = (keys) => {
    return IntegrationService.saveFiriKeys(userStore.user.id, keys);
  };

  const getFiriKeys = () => {
    return IntegrationService.getFiriKeys(userStore.user.id);
  };

  const tradeStore = useTradeStore();

  const addNokPrice = (coinPair, price) => {
    const oldPrice = prices.value[coinPair] ? prices.value[coinPair].price : 0;
    const rank = prices.value[coinPair] ? prices.value[coinPair].rank + 1 : 1;
    const direction = oldPrice < price ? "up" : "down";
    prices.value = {
      ...prices.value,
      [coinPair]: {
        price,
        direction,
        rank,
      },
    };
    tradeStore.updatePrices(price, coinPair, direction);
  };

  const addPrice = _.throttle((coinPair, price) => {
    const oldPrice = prices.value[coinPair] ? prices.value[coinPair].price : 0;
    const rank = prices.value[coinPair] ? prices.value[coinPair].rank + 1 : 1;
    const direction = oldPrice < price ? "up" : "down";
    prices.value = {
      ...prices.value,
      [coinPair]: {
        price,
        direction,
        rank,
      },
    };
    tradeStore.updatePrices(price, coinPair, direction);
  }, 1000);

  const addConnection = (pair) => {
    const url = `wss://stream.binance.com:9443/ws/${pair.toLowerCase()}@trade`;

    const connection =
      connections.value.map((con) => con.url).indexOf(url) !== -1;
    if (connection) {
      return;
    }

    const ws = new WebSocket(url);
    connections.value.push({
      url,
      ws,
    });

    ws.onmessage = (event) => {
      try {
        if (paused.value) return;
        const obj = JSON.parse(event.data);
        addPrice(obj.s, obj.p);
      } catch (error) {
        console.error(error);
      }
    };
  };

  return {
    prices,
    connections,
    paused,
    winner,
    saveBinanceKeys,
    getBinanceKeys,
    saveFiriKeys,
    getFiriKeys,
    addPrice,
    addConnection,
    addNokPrice,
  };
});
