export default {
  async searchCoins(keyword) {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.get("/api/coin/search/" + keyword);
    return data.data;
  },
  async getAll() {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.get("/api/coin");
    return data.data;
  },
};
