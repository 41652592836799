import type { Trade, SuggestionCriteria } from "@/types/trade";

export default {
  async getAll() {
    const { data } = await apiClient.get("/api/trade");
    return data.data;
  },
  async getActive() {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/trade/active", {
      ...apiHeaders,
    });
    return data.value.data;
  },
  async getActiveConverted() {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/trade/active/converted", {
      ...apiHeaders,
    });
    return data.value.data;
  },
  async getLongterm() {
    const { data } = await apiClient.get("/api/trade/longterm");
    return data.data;
  },
  async getClosed() {
    const { data } = await apiClient.get("/api/trade/closed");
    return data.data;
  },
  async getPortfolio() {
    const { data } = await apiClient.get("/api/trade/portfolio");
    return data.data;
  },
  async getSummary() {
    const { data } = await apiClient.get("/api/trade/summary");
    return data.data;
  },
  async get(id) {
    const apiHeaders = await useApiHeaders();
    const { data } = await $fetch("/api/trade/" + id, {
      ...apiHeaders,
    });
    return data;
  },
  async createTrade(trade) {
    const { apiClient } = await useAxios();
    const { data } = await apiClient.post("/api/trade", trade);
    return data.data;
  },
  async updateTrade(trade: Trade) {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/trade/" + trade.id, {
      method: "PUT",
      body: JSON.stringify(trade),
      ...apiHeaders,
    });

    return data.value.data;
  },
  async deleteTrades(tradeIds: string[]) {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/trade", {
      method: "DELETE",
      body: JSON.stringify({ tradeIds: tradeIds }),
      ...apiHeaders,
    });

    return data.value;
  },
};
